import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/info";
export const PageQuery_info_nl_cookiepolicymdx = graphql`
        query PageQuery_info_nl_cookiepolicymdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "info"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/cookie-policy/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Cookiebeleid",
  "path": "/info/cookie-policy",
  "date": "2022-09-29T00:00:00.000Z"
};
const layoutProps = {
  PageQuery_info_nl_cookiepolicymdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "privacybeleid",
      "style": {
        "position": "relative"
      }
    }}>{`Privacybeleid`}<a parentName="h1" {...{
        "href": "#privacybeleid",
        "aria-label": "privacybeleid permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Laatst bijgewerkt: 29 September, 2022`}</p>
    <p>{`Zie het `}<a parentName="p" {...{
        "href": "/info/privacybeleid/#tracking-technologie%C3%ABn-en-cookies"
      }}>{`privacybeleid`}</a>{` voor meer informatie over het gebruik van cookies.`}</p>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      